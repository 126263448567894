import { ASSET_BASE_URL } from "@/constant";
import qs from "qs";
import humps from "humps";
import { configStore } from "@/store/config";

const isContainHttp = (link: string) => {
  if (typeof link !== "string") {
    return false;
  }
  if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
    return true;
  }
  return false;
};

const isContainQueryString = (link: string) => {
  return link.includes("?") ? true : false;
};

const rebuildAssetURL = (link: string) => {
  if (typeof link !== "string") {
    return "";
  }
  if (!isContainHttp(link)) {
    const leadingSlash = link.charAt(0) === "/";
    if (leadingSlash) {
      return `${ASSET_BASE_URL}${link}`;
    }
    return `${ASSET_BASE_URL}/${link}`;
  }
  return link;
};

const pathToURL = (path: string) => {
  const useConfigStore = configStore();
  const pattern = new RegExp("^https?:\\/\\/");
  const apiDomain = new URL(useConfigStore.baseUrl as string);
  const domain = apiDomain.origin;
  return pattern.test(path) ? path : `${domain}${path}`;
};

function getQueryString() {
  const searchParam = window.location.search;
  const parsed = searchParam
    ? humps.camelizeKeys(qs.parse(searchParam, { ignoreQueryPrefix: true }))
    : {};
  return parsed as Record<string, any>;
}

export {
  pathToURL,
  isContainHttp,
  isContainQueryString,
  rebuildAssetURL,
  getQueryString,
};
