export const ROUTE_HOME = "Home";
export const ROUTE_VOUCHER_LANDING = "VoucherLanding";
export const ROUTE_PAYMENT_PAGE = "PaymentPage";
export const ROUTE_PAYMENT_PAGE_V2 = "PaymentPageV2";
export const ROUTE_PAYMENT_FAILED_PAGE = "PaymentFailedPage";
export const ROUTE_VOUCHER_POINT_PAGE = "VoucherPointPage";
export const ROUTE_FORM_ADDRESS_PAGE = "FormAddressPage";
export const ROUTE_LIST_ADDRESS_PAGE = "ListAddressPage";
export const ROUTE_LIST_ADDRESS_MOBILE_PAGE = "ListAddressMobilePage";
export const ROUTE_LIST_ADDRESS_BOOKING_PAGE = "ListAddressBookingPage";
export const ROUTE_FORM_CC_PAGE = "FormCCPage";
export const ROUTE_LIST_CC_PAGE = "ListCCPage";
export const ROUTE_LIST_CC_MOBILE_PAGE = "ListCCMobilePage";
export const ROUTE_SURVEY_PAGE = "SurveyPage";
export const ROUTE_SEARCH_PAGE = "SearchPage";
export const ROUTE_SEARCH_BY_PDT = "SearchByPDT";
export const API_MINOR_VERSION = 3;
export const API_MAJOR_VERSION = "v5";
export const API_PROVIDER = "hungryhub";
export const API_BASE_URL = import.meta.env.VITE_APP_API_DOMAIN;
export const DEFAULT_LANGUAGE = "th";
export const PAYMENT_METHOD_QR = "promptpay";
export const PAYMENT_METHOD_CARD = "credit_card";
export const PAYMENT_METHOD_CC = "card";
export const PAYMENT_METHOD_SHOPEE_PAY = "shopee_pay";
export const PAYMENT_METHOD_TRUEWALLET = "true_wallet";
export const VOUCHER_CATEGORY_GIFT = "gift";
export const VOUCHER_CATEGORY_REDEMPTION = "redemption";
export const FILTER_BY_CUISINE = "filter_cuisine";
export const FILTER_BY_LOCATION = "filter_location";
export const FILTER_BY_PRICE = "filter_price";
export const FILTER_BY_OTHER = "filter_other";
export const FILTER_BY_DINING_STYLE = "filter_dining_style";
export const FILTER_BY_DATE = "filter_date";
export const FILTER_BY_TIME = "filter_time";
export const FILTER_BY_ALL = "filter_all";
export const FILTER_BY_PACKAGE_TYPE = "filter_package_type";
export const FILTER_SORT_BY_AND_DISTANCE = "filter_sort_by_and_distance";
export const ACTION_TYPE_ADD = "add";
export const ACTION_TYPE_EDIT = "edit";
export const FAILED_COONECT_TO_SERVER = "Failed connecting to server";
export const PACKAGE_CODE_AYCE = "ayce";
export const PACKAGE_CODE_PP = "pp";
export const PACKAGE_CODE_HAH = "hah";
export const PACKAGE_CODE_BFP = "bfp";
export const PACKAGE_CODE_SM = "sm";
export const PACKAGE_CODE_HS = "hs";
export const PACKAGE_CODE_XP = "xp";
export const ASSET_BASE_URL = "https://images.hungryhub.com";
export const SORT_BY_PICE_ASC = "price asc";
export const SORT_BY_PRICE_DESC = "price desc";
export const SORT_BY_REVIEWS_SCORE_DESC = "reviews_score desc";
export const SORT_BY_MOST_REVIEW = "most_reviewed";
export const SORT_BY_NEAREST_FIRST = "nearest_first";
export const SORT_BY_MOST_BOOKED = "most_booked";
export const SORT_BY_MOST_RELEVANCE = "most_relevance";
export const CITY_NAME_ALL_CITIES = "All Cities";
export const CITY_NAME_BANGKOK = "Bangkok";
export const CITY_NAME_SINGAPORE = "Singapore";
export const LOCATION_MRT = "mrt_route";
export const LOCATION_BTS = "bts_route";
export const LOCATION_SHOPPING_MALL = "shopping_mall";
export const LOCATION_POPULAR_ZONE = "popular_zone";
export const PRICE_PER_PACKAGE = "per_pack";
export const PRICE_PER_PERSON = "per_person";
