import qs from "qs";
import humps from "humps";
export default function useQueryString() {
  const queryString = window.location.search;
  const parsedQueryString: any = humps.camelizeKeys(
    qs.parse(queryString, {
      ignoreQueryPrefix: true,
      decoder(str, _, charset) {
        const strWithoutPlus = str.replace(/\+/g, " ");
        if (charset === "iso-8859-1") {
          // unescape never throws, no try...catch needed:
          return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
        }

        if (/^(\d+|\d*\.\d+)$/.test(str)) {
          return parseFloat(str);
        }

        const keywords = {
          true: true,
          false: false,
          null: null,
          undefined,
        };
        if (str in keywords) {
          // @ts-ignore
          return keywords[str];
        }

        // utf-8
        try {
          return decodeURIComponent(strWithoutPlus);
        } catch (e) {
          return strWithoutPlus;
        }
      },
    })
  );

  function getQueryString() {
    return parsedQueryString;
  }

  function addQueryString(key: string, val: string | number | boolean) {
    parsedQueryString[key] = val;
  }

  function removeQueryString(key: string) {
    const parsedKey = humps.camelize(key);
    if (parsedKey in parsedQueryString) {
      delete parsedQueryString[parsedKey];
    }
  }

  function getStringifyQueryString() {
    const stringify = qs.stringify(humps.decamelizeKeys(parsedQueryString));
    return stringify;
  }

  return {
    parsedQueryString,
    getQueryString,
    addQueryString,
    getStringifyQueryString,
    removeQueryString,
  };
}
