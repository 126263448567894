{
  "next": "Next",
  "totalAmount": "total amount",
  "pleaseEnterPhone": "Please enter your phone no.",
  "pleaseEnterEmail": "Please enter your email address",
  "shopeePay": "SHOPEE PAY",
  "debitCredit": "DEBIT / CREDIT CARD",
  "qrPayment": "QR Code",
  "shopeePayPaymentText": "The system will redirect to Shopee Pay application or website",
  "trueWalletPaymentText": "The system will redirect to True Wallet payment page",
  "weWillGenerateAmountPayment": "We will generate QR code for the amount {amount} Baht.",
  "useYourBank": "Use your Bank App to scan the QR code",
  "pleaseWait": "Please Wait",
  "search": "Search...",
  "applyFilter": "Apply",
  "ayce": "all you can eat",
  "priceRange": "Price Range",
  "pp": "party pack",
  "startingPrice": "Starting Price",
  "highestPrice": "Highest Price",
  "showResult": "Show ({total} Result)",
  "showResults": "Show ({total} Results)",
  "whatsYourBudget": "What's your budget?",
  "underPrice": "Under {amount}",
  "pricePerPerson": "Price Per Person",
  "pricePerPackage": "Price Per Package",
  "popularPlace": "Popular Place",
  "allLocation": "All Location",
  "shoppingMall": "Shopping Mall",
  "popularZone": "Popular Zone",
  "btsRoute": "BTS Route",
  "mrtRoute": "MRT Route",
  "seeMore": "See More",
  "seeLess": "See Less",
  "name": "Name<sup>*</sup>",
  "egHomeOffice": "e.g. Home, Office",
  "address": "Address<sup>*</sup>",
  "addressDetails": "Address Details",
  "egFloorUnit": "e.g. Floor, unit number, building",
  "noteToDriver": "Note to Driver",
  "contactName": "Contact Name",
  "contactNumber": "Contact Number",
  "setPrimary": "Set Primary",
  "save": "Save",
  "cancel": "Cancel",
  "delete": "Delete",
  "myAddress": "My Addresses",
  "addAddress": "Add Address",
  "editAddress": "Edit Address",
  "addNewAddress": "Add New Address",
  "edit": "Edit",
  "paymentMethod": "Payment Methods",
  "addCreditDebitCard": "Add Credit/Debit Card",
  "creditCardType": "Credit Card Type",
  "cardNumber": "Card Number",
  "cardHolderName": "Card Holder's Name",
  "expiresOn": "Expires On",
  "securityCodeCVV": "Security Code (CVV)",
  "pickLocation": "Pick Location",
  "primary": "Primary",
  "currentLocation": "Current Location",
  "savedAddress": "Saved Address",
  "recentlyUsed": "Recently Used",
  "surveyStepOneTitle": "Let us find the best recommendations for you",
  "surveyStepOneSubtitle": "With deals and experience for special occasions from over 1,000 leading restaurants and hotels",
  "surveyStepTwoTitle": "Choose your favorite cuisine",
  "surveyStepTwoSubtitle": "You can choose up to 5 choices",
  "surveyStepThreeTitle": "What’s your city?",
  "surveyStepThreeSubtitle": "You can change this again later",
  "getStarted": "Get Started",
  "showMeGreatDeals": "Show me great deals!",
  "loginErrorMessage": "Please register/log in to use promo code",
  "new": "New",
  "trending": "Trending",
  "popular": "Popular",
  "bonus": "Bonus",
  "earnPoints": "Earn Points",
  "from": "From",
  "searchRestaurantPlaceHolder": "Search Restaurant, Cuisine, Location",
  "findARestaurant": "Find a Restaurant",
  "dineIn": "Dine in",
  "experience": "Experience",
  "delivery": "Delivery",
  "cuisines": "Cuisines",
  "cuisine": "Cuisine",
  "price": "Price",
  "location": "Location",
  "other": "Other",
  "clear": "Clear",
  "noResultFound": "No result found",
  "clearFilter": "Clear filters",
  "searchResults": "Search results",
  "orYouMaybeInterested": "Or you may be interested",
  "first5Reviews": "first 5 reviews",
  "sortBy": "sort by",
  "selectedFilter": "selected filter",
  "sortResultBy": "Sort Result By",
  "lowestPrice": "Lowest Price",
  "mostLoved": "Most Loved",
  "mostRelevance": "Most Relevance",
  "mostBooked": "Most Booked",
  "facility": "Facility",
  "ratingScores": "Rating Scores",
  "mostReviewed": "Most Reviewed",
  "nearestFirst": "Nearest First",
  "distance": "Distance",
  "weCantFindResult": "We can’t find your locations.",
  "turnOnLocation": "Turn On Location",
  "filterByPDT": "Filter by people, date, and time",
  "howManyPeople": "How Many People?",
  "bigGroup": "Big Group",
  "adult": "adult | adults",
  "kid": "kid | kids",
  "selectDate": "Select Date",
  "upTo90DaysInAdvance": "Up to 90 days in advance",
  "today": "Today",
  "daysOfWeek": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  "selectTime": "Select Time",
  "atLeast15MinsInAdvance": "At least 15 mins in advance",
  "apply": "Apply",
  "filter": "filters",
  "packageType": "Package Type",
  "results": "Results",
  "diningStyle": "Dining Style"
}
