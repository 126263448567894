import { debug } from "@/config";

function logger(name: string, val: any) {
  if (!debug) {
    return;
  }
  const logWrapper = document.getElementById("log-wrapper");
  if (typeof val === "object" && Array.isArray(val) === false) {
    console.log("start debug", val);

    console.group();
    for (const key in val) {
      if (Object.prototype.hasOwnProperty.call(val, key)) {
        const element = val[key];
        console.log("debug", name, key, val[key]);
      }
    }
    console.groupEnd();
  } else if (Array.isArray(val)) {
    console.log("start debug", val);
    console.group();
    val.forEach((item) => {
      console.log("debug", name, item);
    });
    console.groupEnd();
  } else {
    console.log("debug", name, val);
  }
}

export default logger;
