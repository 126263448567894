import { defineStore } from "pinia";
import useHttp from "@/composable/useHttp";
import { ConfigData, Features } from "@/typing/app";

export const configStore = defineStore("config", {
  state: () => ({
    isLoading: false,
    baseUrl: import.meta.env.VITE_APP_API_DOMAIN,
    features: {
      firstAppVoucher: false,
      voucherInMarketplace: false,
      enableSaveCc: false,
      enableSaveAddress: false,
      enableMapbox: false,
      enableSearchByDtp: false,
    } as Features,
  }),
  getters: {
    isSignedIn(state) {
      return state.features;
    },
  },
  actions: {
    async getConfig() {
      this.isLoading = true;
      const response = await useHttp({
        url: `${this.baseUrl}/config.json`,
      });
      const responseData = response.data as {
        success: boolean;
        message: string;
        data: ConfigData;
      };
      if (responseData && responseData.success) {
        this.features = {
          ...this.features,
          ...responseData.data.features,
        };
      }
      this.isLoading = false;
    },
  },
});
