const Home = () => import("@/pages/Home.vue");
const VoucherLandingVue = () => import("@/pages/VoucherLanding.vue");
const PaymentPage = () => import("@/pages/payment/PaymentPage.vue");
const PaymentPageV2 = () => import("@/pages/payment/PaymentPageV2.vue");
const PaymentFailedPage = () => import("@/pages/payment/PaymentFailedPage.vue");
const FormAddressPage = () => import("@/pages/address/FormAddressPage.vue");
const ListAddressPage = () => import("@/pages/address/ListAddressPage.vue");
const ListAddressMobilePage = () =>
  import("@/pages/address/ListAddressMobilePage.vue");
const ListAddressBookingPage = () =>
  import("@/pages/address/ListAddressBookingPage.vue");
const FormCCPage = () => import("@/pages/credit_card/FormCCPage.vue");
const ListCCPage = () => import("@/pages/credit_card/ListCCPage.vue");
const ListCCMobilePage = () =>
  import("@/pages/credit_card/ListCCMobilePage.vue");
const SurveyPage = () => import("@/pages/survey/SurveyPage.vue");
const VoucherPoint = () => import("@/pages/voucher_point/VoucherPointPage.vue");
const FilterByCuisine = () =>
  import("@/pages/filter/cuisine/FilterByCuisine.vue");
const FilterByDiningStyle = () =>
  import("@/pages/filter/dining_style/FilterByDiningStyle.vue");
const FilterByLocation = () =>
  import("@/pages/filter/location/FilterByLocation.vue");
const FilterByPrice = () => import("@/pages/filter/price/FilterByPrice.vue");
const FilterByOther = () => import("@/pages/filter/other/FilterByOther.vue");
const FilterSortByAndDistance = () =>
  import("@/pages/filter/sort_by/FilterSortByAndDistance.vue");
const FilterByDate = () => import("@/pages/filter/pdt/FilterByDate.vue");
const FilterByTime = () => import("@/pages/filter/pdt/FilterByTime.vue");
const SearchByPDT = () => import("@/pages/search/SearchByPDT.vue");
import { createRouter, createWebHistory } from "vue-router";
import {
  ROUTE_VOUCHER_LANDING,
  ROUTE_HOME,
  ROUTE_PAYMENT_PAGE,
  ROUTE_PAYMENT_PAGE_V2,
  ROUTE_PAYMENT_FAILED_PAGE,
  ROUTE_VOUCHER_POINT_PAGE,
  ROUTE_FORM_ADDRESS_PAGE,
  ROUTE_LIST_ADDRESS_PAGE,
  ROUTE_LIST_ADDRESS_MOBILE_PAGE,
  ROUTE_LIST_ADDRESS_BOOKING_PAGE,
  ROUTE_FORM_CC_PAGE,
  ROUTE_LIST_CC_PAGE,
  ROUTE_LIST_CC_MOBILE_PAGE,
  ROUTE_SURVEY_PAGE,
  FILTER_BY_CUISINE,
  FILTER_BY_LOCATION,
  FILTER_BY_DINING_STYLE,
  FILTER_BY_PRICE,
  FILTER_BY_OTHER,
  ROUTE_SEARCH_PAGE,
  FILTER_SORT_BY_AND_DISTANCE,
  FILTER_BY_DATE,
  FILTER_BY_TIME,
  ROUTE_SEARCH_BY_PDT,
} from "../constant";

const SearchPage = () => import("@/pages/search/SearchPage.vue");

const routes = [
  {
    path: "/",
    name: ROUTE_HOME,
    component: Home,
  },
  {
    path: "/vouchers/:voucherId",
    name: ROUTE_VOUCHER_LANDING,
    component: VoucherLandingVue,
    props: true,
  },
  {
    path: "/search",
    name: ROUTE_SEARCH_PAGE,
    component: SearchPage,
  },
  {
    path: "/payment",
    name: ROUTE_PAYMENT_PAGE,
    component: PaymentPage,
    props: true,
  },
  {
    path: "/payment-v2",
    name: ROUTE_PAYMENT_PAGE_V2,
    component: PaymentPageV2,
    props: true,
  },
  {
    path: "/payment-failed",
    name: ROUTE_PAYMENT_FAILED_PAGE,
    component: PaymentFailedPage,
    props: true,
  },
  {
    path: "/offers",
    name: ROUTE_VOUCHER_POINT_PAGE,
    component: VoucherPoint,
    props: true,
  },
  {
    path: "/filter-cuisine",
    name: FILTER_BY_CUISINE,
    component: FilterByCuisine,
    props: true,
  },
  {
    path: "/filter-dining-style",
    name: FILTER_BY_DINING_STYLE,
    component: FilterByDiningStyle,
    props: true,
  },
  {
    path: "/filter-location",
    name: FILTER_BY_LOCATION,
    component: FilterByLocation,
    props: true,
  },
  {
    path: "/filter-price",
    name: FILTER_BY_PRICE,
    component: FilterByPrice,
    props: true,
  },
  {
    path: "/filter-other",
    name: FILTER_BY_OTHER,
    component: FilterByOther,
    props: true,
  },
  {
    path: "/filter-sort-by-and-distance",
    name: FILTER_SORT_BY_AND_DISTANCE,
    component: FilterSortByAndDistance,
    props: true,
  },
  {
    path: "/filter-date",
    name: FILTER_BY_DATE,
    component: FilterByDate,
    props: true,
  },
  {
    path: "/filter-time",
    name: FILTER_BY_TIME,
    component: FilterByTime,
    props: true,
  },
  {
    path: "/form-address",
    name: ROUTE_FORM_ADDRESS_PAGE,
    component: FormAddressPage,
    props: true,
  },
  {
    path: "/list-address",
    name: ROUTE_LIST_ADDRESS_PAGE,
    component: ListAddressPage,
    props: true,
  },
  {
    path: "/list-address-mobile",
    name: ROUTE_LIST_ADDRESS_MOBILE_PAGE,
    component: ListAddressMobilePage,
    props: true,
  },
  {
    path: "/list-address-booking",
    name: ROUTE_LIST_ADDRESS_BOOKING_PAGE,
    component: ListAddressBookingPage,
    props: true,
  },
  {
    path: "/form-cc",
    name: ROUTE_FORM_CC_PAGE,
    component: FormCCPage,
    props: true,
  },
  {
    path: "/list-cc",
    name: ROUTE_LIST_CC_PAGE,
    component: ListCCPage,
    props: true,
  },
  {
    path: "/list-cc-mobile",
    name: ROUTE_LIST_CC_MOBILE_PAGE,
    component: ListCCMobilePage,
    props: true,
  },
  {
    path: "/survey",
    name: ROUTE_SURVEY_PAGE,
    component: SurveyPage,
    props: true,
  },
  {
    path: "/search-by-pdt",
    name: ROUTE_SEARCH_BY_PDT,
    component: SearchByPDT,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
