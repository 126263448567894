import "@vueform/slider/themes/default.css";
import "./assets/css/tailwind.css";
import "./assets/css/index.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router/router";
import i18n from "./i18n";
import { initEventManager } from "./logic/eventManager";
import useReport from "./composable/useReport";

const app = createApp(App);
initEventManager();

app.use(createPinia());
app.use(i18n);
app.use(router);
app.mount("#app");
app.config.errorHandler = (err, instance, info) => {
  useReport({
    level: "error",
    message: info,
    errorException: err,
  });
};
