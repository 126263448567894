{
  "next": "ถัดไป",
  "totalAmount": "สรุปยอดเงินทั้งหมด",
  "pleaseEnterPhone": "กรุณากรอกหมายเลขโทรศัพท์ของคุณ",
  "pleaseEnterEmail": "กรุณากรอกอีเมลของคุณ",
  "shopeePay": "SHOPEE PAY",
  "debitCredit": "บัตรเดบิต / บัตรเครดิต",
  "qrPayment": "จ่ายด้วย QR Code",
  "shopeePayPaymentText": "ระบบจะทำการเชื่อมต่อไปที่ Shopee Pay โดยอัตโนมัติ",
  "trueWalletPaymentText": "ระบบจะทำการเชื่อมต่อไปที่ True Wallet โดยอัตโนมัติ",
  "weWillGenerateAmountPayment": "ระบบจะสร้าง QR Code สำหรับชำระเงิน {amount} บาท",
  "useYourBank": "หลังจากกดปุ่ม 'ยืนยันและชำระเงิน' โปรดใช้แอปพลิเคชั่นธนาคารของคุณแสกน QR Code เพื่อชำระเงิน",
  "pleaseWait": "โปรดรอ",
  "search": "ค้นหา...",
  "applyFilter": "ใช้ตัวกรอง",
  "priceRange": "ช่วงราคา",
  "startingPrice": "ราคาเริ่มต้น",
  "highestPrice": "ราคาสูงสุด",
  "showResult": "แสดง ({total} ผลลัพธ์)",
  "showResults": "แสดง ({total} ผลลัพธ์)",
  "whatsYourBudget": "คุณตั้งงบไว้เท่าไหร่?",
  "pp": "ปาร์ตี้แพ็ก",
  "underPrice": "ต่ำกว่า {amount} บาท",
  "pricePerPerson": "ราคาต่อคน",
  "pricePerPackage": "ราคาต่อแพ็กเกจ",
  "popularPlace": "สถานที่ยอดนิยม",
  "allLocation": "สถานที่ทั้งหมด",
  "ayce": "บุฟเฟต์",
  "shoppingMall": "ห้างสรรพสินค้า",
  "popularZone": "ย่านดัง",
  "btsRoute": "เส้นทาง BTS",
  "mrtRoute": "เส้นทาง MRT",
  "seeMore": "ดูทั้งหมด",
  "seeLess": "แสดงน้อยลง",
  "name": "ชื่อ",
  "egHomeOffice": "บ้าน, ออฟฟิศ",
  "address": "ที่อยู่",
  "addressDetails": "รายละเอียดที่อยู่",
  "egFloorUnit": "ชั้นที่เท่าไร, เลขที่เท่าไร, อาคารไหน",
  "noteToDriver": "รายละเอียดถึงคนขับ",
  "contactName": "ชื่อ",
  "contactNumber": "เบอร์โทร",
  "setPrimary": "ตั้งเป็นที่อยู่หลัก",
  "save": "บันทึก",
  "cancel": "ยกเลิก",
  "delete": "ลบ",
  "myAddress": "ที่อยู่",
  "addAddress": "เพิ่มที่อยู่",
  "editAddress": "แก้ไขที่อยู่",
  "addNewAddress": "เพิ่มที่อยู่ใหม่",
  "edit": "แก้ไข",
  "paymentMethod": "วิธีการชำระเงิน",
  "addCreditDebitCard": "เพิ่มบัตรใหม่",
  "creditCardType": "ประเภทบัตรเครดิต",
  "cardNumber": "หมายเลขบัตร 16 หลัก",
  "cardHolderName": "ชื่อผู้ถือบัตร",
  "expiresOn": "วันหมดอายุ",
  "securityCodeCVV": "รหัสรักษาความปลอดภัย",
  "pickLocation": "ระบุที่อยู่",
  "primary": "บัตรใบหลัก",
  "currentLocation": "ตำแหน่งปัจจุบัน",
  "savedAddress": "ที่อยู่ที่บันทึกไว้",
  "recentlyUsed": "ที่อยู่ล่าสุด",
  "surveyStepOneTitle": "ให้เราช่วยแนะนำความต้องการที่เหมาะกับคุณ",
  "surveyStepOneSubtitle": "เรามีดีลเด็ด ๆ และประสบการณ์ดี ๆ อีกมากมายให้คุณเลือกกว่า 1,000 ร้านอาหารและโรงแรม",
  "surveyStepTwoTitle": "เลือกประเภทร้านที่คุณชื่นชอบ",
  "surveyStepTwoSubtitle": "สามารถเลือกได้สูงสุด 5 ตัวเลือก",
  "surveyStepThreeTitle": "คุณอาศัยอยู่ที่ไหน?",
  "surveyStepThreeSubtitle": "คุณสามารถเลือกที่อยู่ใหม่ได้ตลอดเวลา",
  "getStarted": "เริ่ม",
  "showMeGreatDeals": "โชว์ผลลัพธ์",
  "loginErrorMessage": "กรุณาสมัคร/เข้าสู่ระบบเพื่อใช้โค้ด",
  "new": "ใหม่",
  "trending": "ร้านฮิต",
  "popular": "มาแรง",
  "bonus": "โบนัส",
  "earnPoints": "รับคะแนน",
  "from": "จาก",
  "searchRestaurantPlaceHolder": "ชื่อร้านอาหาร, ประเภท, สถานที่",
  "findARestaurant": "ค้นหาร้านอาหาร",
  "dineIn": "ทานที่ร้าน",
  "experience": "เอ็กซ์พีเรียนซ์",
  "delivery": "เดลิเวอรี่",
  "cuisines": "อาหาร",
  "cuisine": "อาหาร",
  "price": "ราคา",
  "location": "พื้นที่",
  "other": "อื่น ๆ",
  "clear": "ล้าง",
  "noResultFound": "ไม่พบผลลัพธ์",
  "clearFilter": "รีเซ็ตตัวกรอง",
  "searchResults": "ผลการค้นหา",
  "orYouMaybeInterested": "คุณน่าจะสนใจ",
  "first5Reviews": "5 รีวิวแรก",
  "sortBy": "เรียงตาม",
  "selectedFilter": "ฟิลเตอร์ที่เลือกไว้",
  "sortResultBy": "เรียงตาม",
  "lowestPrice": "ราคาต่ำไปสูง",
  "mostLoved": "คะแนนรีวิว",
  "mostRelevance": "ค่าเริ่มต้น",
  "mostBooked": "จำนวนการจอง",
  "facility": "สิ่งอำนวยความสะดวก",
  "nearestFirst": "ใกล้ที่สุดก่อน",
  "ratingScores": "ความนิยม",
  "mostReviewed": "คะแนนรีวิว",
  "distance": "ระยะทาง",
  "weCantFindResult": "เราไม่พบสถานที่ของคุณ.",
  "turnOnLocation": "เปิดตำแหน่งที่ตั้ง",
  "filterByPDT": "กรุณากรอก จำนวนคน วันที่ และ เวลา",
  "howManyPeople": "ระบุจำนวนคน",
  "bigGroup": "กลุ่มใหญ่",
  "adult": "ผู้ใหญ่",
  "kid": "เด็ก",
  "selectDate": "เลือกวันที่",
  "upTo90DaysInAdvance": "จองล่วงหน้าได้ถึง 90 วัน",
  "today": "วันนี้",
  "daysOfWeek": ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
  "selectTime": "เลือกเวลา",
  "atLeast15MinsInAdvance": "จองล่วงหน้าอย่างน้อย 15 นาที",
  "apply": "ค้นหา",
  "filter": "กรอง",
  "packageType": "ประเภทแพ็กเกจ",
  "results": "ผลลัพธ์",
  "diningStyle": "สไตล์การรับประทานอาหาร"
}
