import mitt from "mitt";
import type { Emitter } from "mitt";
type ValidEvents = Record<string, unknown>;

let eventManager = "" as unknown as Emitter<ValidEvents>;
// rule: start name with 'on' for event that triggered by web, and start name with 'do' for event that triggered by other platform
const EVENT_DO_SETUP_DATA = "doSetupData";
const EVENT_DO_SETUP_DATA_SEARCH_PAGE = "doSetupDataSearchPage";
const EVENT_ON_VOUCHER_REMOVED = "onVoucherRemoved";
const EVENT_ON_HEIGHT_CHANGED = "onHeightChanged";
const EVENT_ON_PAYMENT_SELECTED = "onPaymentSelected";
const EVENT_ON_SIGN_IN_BANNER_CLICKED = "onSignInBannerClicked";
const EVENT_ON_CONFIRM_REDEEM_POINTS = "onConfirmRedeemPoints";
const EVENT_ON_CONFIRM_SELECT_VOUCHERS = "onConfirmSelectVouchers";
const EVENT_DO_RENDER_PAYMENT_METHOD = "doRenderPaymentMethod";
const EVENT_DO_VALIDATE_VOUCHERS = "doValidateVouchers";
const EVENT_ON_MOUNTED = "onMounted";
const EVENT_ON_MOUNTED_SEARCH_PAGE = "onMountedSearchPage";
const EVENT_ON_MOUNTED_SEARCH_PAGE_PDT = "onMountedSearchPagePdt";
const EVENT_ON_TAB_CHANGED = "onTabChanged";
const EVENT_DO_CONFIRM_VOUCHER = "doConfirmVoucher";
const EVENT_DO_CONFIRM_REDEEM_POINTS = "doConfirmRedeemPoints";
const EVENT_DO_CONFIRM_PAYMENT_METHOD = "doConfirmPaymentMethod";
const EVENT_ON_APP_BRIDGE_READY = "onAppBridgeReady";
const EVENT_ON_TNC_CLICKED = "onTermConditionClicked";
const EVENT_ON_TRY_RENDER_AGAIN = "onTryRenderAgain";
const EVENT_ON_CUISINES_SELECTED = "onCuisinesSelected";
const EVENT_ON_DINING_STYLES_SELECTED = "onDiningStylesSelected";
const EVENT_ON_LOCATIONS_SELECTED = "onLocationsSelected";
const EVENT_ON_PDT_SELECTED = "onPdtSelected";
const EVENT_ON_PRICE_SELECTED = "onPriceSelected";
const EVENT_ON_OTHER_SELECTED = "onOthersSelected";
// Credit Card
const EVENT_DO_RENDER_CC = "doRenderCC";
const EVENT_ON_PRIMARY_CC_CHANGED = "onPrimaryCCChanged";
const EVENT_ON_CHANGE_PRIMARY_CC = "onChangePrimaryCC";
const EVENT_DO_CONFIRM_CHANGE_PRIMARY_CC = "doConfirmChangePrimaryCC";
const EVENT_ON_DELETE_CC = "onDeleteCC";
const EVENT_DO_CONFIRM_DELETE_CC = "doConfirmDeleteCC";
const EVENT_ON_ADD_CREDIT_CARD = "onAddCreditCard";
const EVENT_DO_ADD_CREDIT_CARD = "doAddCreditCard";
const EVENT_ON_CREDIT_CARD_SAVED = "onCreditCardSaved";
const EVENT_ON_CREDIT_CARD_CANCELLED = "onCreditCardCancelled";
const EVENT_ON_CREDIT_CARD_DETAIL = "onCreditCardDetail";
const EVENT_DO_BACK_TO_PAYMENT_METHOD = "doBackToPaymentMethod";
// Address
const EVENT_DO_RENDER_ADDRESS = "doRenderAddress";
const EVENT_ON_CHANGE_PRIMARY_ADDRESS = "onChangePrimaryAddress";
const EVENT_DO_CONFIRM_CHANGE_PRIMARY_ADDRESS = "doConfirmChangePrimaryAddress";
const EVENT_ON_DELETE_ADDRESS = "onDeleteAddress";
const EVENT_DO_CONFIRM_DELETE_ADDRESS = "doConfirmDeleteAddress";
const EVENT_ON_PICK_LOCATION = "onPickLocation";
const EVENT_DO_PICK_LOCATION = "doPickLocation";
const EVENT_ON_ADDRESS_SAVED = "onAddressSaved";
const EVENT_ON_ADDRESS_UPDATED = "onAddressUpdated";
const EVENT_ON_ADDRESS_DELETED = "onAddressDeleted";
const EVENT_ON_ADDRESS_CANCELLED = "onAddressCancelled";
const EVENT_ON_EDIT_ADDRESS = "onEditAddress";
const EVENT_ON_ADD_ADDRESS = "onAddAddress";
const EVENT_ON_ADDRESS_PICKED = "onAddressPicked";
const EVENT_ON_CURRENT_LOCATION_PICKED = "onCurrentLocationPicked";
// Payment Failed
const EVENT_DO_RENDER_PAYMENT_FAILED = "doRenderPaymentFailed";
const EVENT_ON_TRY_AGAIN = "onTryAgain";
// Survey
const EVENT_DO_RENDER_SURVEY = "doRenderSurvey";
const EVENT_DO_SURVEY_PREV_STEP = "doSurveyPrevStep";
const EVENT_DO_SURVEY_NEXT_STEP = "doSurveyNextStep";
const EVENT_ON_SURVEY_STEP_CHANGED = "onSurveyStepChanged";
const EVENT_ON_SURVEY_SUBMITTED = "onSurveySubmitted";
// Search Page
const EVENT_ON_CLICK_RESTAURANT = "onClickRestaurant";
const EVENT_ON_CLICK_FEATURED_RESTAURANT = "onClickFeaturedRestaurant";
const EVENT_DO_SEARCH = "doSearch";
const EVENT_ON_SEARCH_FINISH = "onSearchFinish";
const EVENT_ON_MAP_CLICK = "onIconMapClick";
const EVENT_ON_INPUT_FOCUS = "onInputFocus";
const EVENT_DO_CHANGE_LAYOUT = "doChangeLayout";
const EVENT_ON_REQUEST_ACCESS_LOCATION = "onRequestAccessLocation";
const EVENT_DO_ACCEPT_ACCESS_LOCATION = "doAcceptAccessLocation";
const EVENT_ON_FILTER_BY_CUISINE = "onFilterByCuisine";
const EVENT_ON_FILTER_BY_LOCATION = "onFilterByLocation";
const EVENT_ON_FILTER_BY_PRICE = "onFilterByPrice";
const EVENT_ON_FILTER_BY_OTHER = "onFilterByOther";
const EVENT_ON_REQUEST_FILTER_SORT_BY = "onRequestFilterSortBy";
const EVENT_ON_REMOVE_SELECTED_FILTER = "onRemoveSelectedFilter";
const EVENT_ON_ORDER_BY = "onOrderBy";
const EVENT_DO_CLEAR_MAP_LOCATION = "doClearMapLocation";
const EVENT_ON_RESET_FILTER = "onResetFilter";
const EVENT_ON_DATE_SELECTED = "onDateSelected";
const EVENT_ON_TIME_SELECTED = "onTimeSelected";
const EVENT_ON_HEIGHT_CHANGED_PDT = "onHeightChangedPDT";

function initEventManager() {
  eventManager = mitt<ValidEvents>();
}

export {
  initEventManager,
  eventManager,
  EVENT_DO_SETUP_DATA,
  EVENT_DO_SETUP_DATA_SEARCH_PAGE,
  EVENT_ON_VOUCHER_REMOVED,
  EVENT_ON_HEIGHT_CHANGED,
  EVENT_ON_PAYMENT_SELECTED,
  EVENT_DO_RENDER_PAYMENT_METHOD,
  EVENT_ON_CONFIRM_SELECT_VOUCHERS,
  EVENT_ON_SIGN_IN_BANNER_CLICKED,
  EVENT_ON_CONFIRM_REDEEM_POINTS,
  EVENT_DO_VALIDATE_VOUCHERS,
  EVENT_ON_MOUNTED,
  EVENT_ON_MOUNTED_SEARCH_PAGE,
  EVENT_ON_MOUNTED_SEARCH_PAGE_PDT,
  EVENT_DO_CONFIRM_VOUCHER,
  EVENT_ON_TAB_CHANGED,
  EVENT_DO_CONFIRM_REDEEM_POINTS,
  EVENT_DO_CONFIRM_PAYMENT_METHOD,
  EVENT_ON_APP_BRIDGE_READY,
  EVENT_ON_TNC_CLICKED,
  EVENT_ON_TRY_RENDER_AGAIN,
  EVENT_ON_CUISINES_SELECTED,
  EVENT_ON_DINING_STYLES_SELECTED,
  EVENT_ON_LOCATIONS_SELECTED,
  EVENT_ON_PRICE_SELECTED,
  EVENT_ON_OTHER_SELECTED,
  EVENT_DO_RENDER_CC,
  EVENT_ON_PRIMARY_CC_CHANGED,
  EVENT_ON_CHANGE_PRIMARY_CC,
  EVENT_DO_CONFIRM_CHANGE_PRIMARY_CC,
  EVENT_ON_DELETE_CC,
  EVENT_DO_CONFIRM_DELETE_CC,
  EVENT_ON_ADD_CREDIT_CARD,
  EVENT_DO_ADD_CREDIT_CARD,
  EVENT_ON_CREDIT_CARD_SAVED,
  EVENT_ON_CREDIT_CARD_CANCELLED,
  EVENT_ON_CREDIT_CARD_DETAIL,
  EVENT_DO_BACK_TO_PAYMENT_METHOD,
  EVENT_DO_RENDER_ADDRESS,
  EVENT_ON_CHANGE_PRIMARY_ADDRESS,
  EVENT_DO_CONFIRM_CHANGE_PRIMARY_ADDRESS,
  EVENT_ON_DELETE_ADDRESS,
  EVENT_DO_CONFIRM_DELETE_ADDRESS,
  EVENT_ON_PICK_LOCATION,
  EVENT_DO_PICK_LOCATION,
  EVENT_ON_ADDRESS_SAVED,
  EVENT_ON_ADDRESS_UPDATED,
  EVENT_ON_ADDRESS_DELETED,
  EVENT_ON_ADDRESS_CANCELLED,
  EVENT_ON_EDIT_ADDRESS,
  EVENT_ON_ADD_ADDRESS,
  EVENT_ON_ADDRESS_PICKED,
  EVENT_ON_CURRENT_LOCATION_PICKED,
  EVENT_DO_RENDER_PAYMENT_FAILED,
  EVENT_ON_TRY_AGAIN,
  EVENT_DO_RENDER_SURVEY,
  EVENT_DO_SURVEY_PREV_STEP,
  EVENT_DO_SURVEY_NEXT_STEP,
  EVENT_ON_SURVEY_STEP_CHANGED,
  EVENT_ON_SURVEY_SUBMITTED,
  EVENT_ON_CLICK_FEATURED_RESTAURANT,
  EVENT_ON_CLICK_RESTAURANT,
  EVENT_DO_SEARCH,
  EVENT_ON_SEARCH_FINISH,
  EVENT_ON_MAP_CLICK,
  EVENT_ON_INPUT_FOCUS,
  EVENT_DO_CHANGE_LAYOUT,
  EVENT_DO_ACCEPT_ACCESS_LOCATION,
  EVENT_ON_REQUEST_ACCESS_LOCATION,
  EVENT_ON_FILTER_BY_CUISINE,
  EVENT_ON_FILTER_BY_LOCATION,
  EVENT_ON_FILTER_BY_OTHER,
  EVENT_ON_FILTER_BY_PRICE,
  EVENT_ON_ORDER_BY,
  EVENT_ON_REQUEST_FILTER_SORT_BY,
  EVENT_ON_REMOVE_SELECTED_FILTER,
  EVENT_DO_CLEAR_MAP_LOCATION,
  EVENT_ON_RESET_FILTER,
  EVENT_ON_PDT_SELECTED,
  EVENT_ON_DATE_SELECTED,
  EVENT_ON_TIME_SELECTED,
  EVENT_ON_HEIGHT_CHANGED_PDT,
};
