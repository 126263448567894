import humps from "humps";
import logger from "./logger";

export function convertToJsonObject(data: string | object, toCamelCase = true) {
  const jsonData = typeof data === "string" ? JSON.parse(data) : data;
  if (toCamelCase) {
    return humps.camelizeKeys(jsonData);
  }
  return jsonData;
}

export function convertToJsonString(
  data: Record<string, any>,
  isWebClient = true
) {
  logger("before transform to json string", data);
  let transformed = {};
  if (isWebClient == true) {
    transformed = humps.camelizeKeys(data);
  } else {
    transformed = humps.decamelizeKeys(data);
  }
  logger("after transform to json string", transformed);
  return transformed;
}
