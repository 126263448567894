import axios, { isAxiosError, AxiosRequestConfig } from "axios";
import humps from "humps";
import qs from "qs";

type paramConfig = {
  url: string;
  method?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  headers?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  canRetry?: boolean;
  canCancel?: boolean;
  withCredentials?: boolean;
  lang?: string;
  camelizePayload?: boolean;
  clientType?: string;
};

type State = {
  data: unknown | null;
  httpStatus: number;
  error: {
    message: string;
    detail: unknown;
  };
};

async function useHttp(paramConfig: paramConfig): Promise<State> {
  const state: State = {
    data: null,
    httpStatus: 0,
    error: {
      message: "",
      detail: undefined as unknown,
    },
  };

  try {
    const DEFAULT_HEADERS = {
      "Content-Type": "application/json",
      "X-HH-Language": paramConfig.lang || "th",
    } as any;
    const REQUIRED_PARAMS = {
      client_type: paramConfig.clientType || "web",
    };

    const options: AxiosRequestConfig = {
      method: paramConfig.method || "GET",
      headers: { ...DEFAULT_HEADERS, ...paramConfig.headers },
    };

    if (paramConfig.data) {
      if (!paramConfig.camelizePayload) {
        options.data = humps.decamelizeKeys(paramConfig.data);
      } else {
        options.data = paramConfig.data;
      }
    }

    const isURLContainQueryString = paramConfig.url.includes("?")
      ? true
      : false;

    let parsedParamsStringify = qs.stringify(REQUIRED_PARAMS, {
      encode: false,
    });
    if (paramConfig.params) {
      parsedParamsStringify = qs.stringify(
        humps.decamelizeKeys({
          ...REQUIRED_PARAMS,
          ...paramConfig.params,
        }),
        {
          encode: false,
        }
      );
    }

    paramConfig.url = isURLContainQueryString
      ? `${paramConfig.url}&${parsedParamsStringify}`
      : `${paramConfig.url}?${parsedParamsStringify}`;

    const response = await axios(paramConfig.url, options);
    state.data = humps.camelizeKeys(response.data);
    state.httpStatus = response.status;
  } catch (err) {
    if (isAxiosError(err)) {
      state.httpStatus = 0;
      state.error.message = err.response?.data.message || "";
      state.error.detail = err;
    } else {
      state.error.detail = err;
      state.error.message = "Something went wrong when setup http call";
    }
  }
  return state;
}

export default useHttp;
