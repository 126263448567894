import { getQueryString } from "@/helper/url";

const queryString = getQueryString();

const {
  hideNavbar,
  screen,
  hideAmount,
  lang: paramLang,
  showConfirmButton,
  embed,
  apiDomain,
  debug,
  clientType,
  callerDomain,
  searchPageLayout,
  isBookingAoa,
} = queryString;

let apiBaseUrl = "";
if (typeof apiDomain === "string" && apiDomain.length > 0) {
  apiBaseUrl = `${atob(apiDomain)}/api/v5`;
} else {
  apiBaseUrl = import.meta.env.VITE_APP_API_DOMAIN;
}
const isProduction = import.meta.env.VITE_APP_MODE === "production";
const termConditionLink =
  "https://hungryhub.zendesk.com/hc/en-us/sections/360001180074-Hungry-Point-%E0%B8%84%E0%B8%B0%E0%B9%81%E0%B8%99%E0%B8%99%E0%B8%AA%E0%B8%B0%E0%B8%AA%E0%B8%A1-";

const acceptedLanguage = ["th", "en"];
let lang = acceptedLanguage.includes(paramLang) ? paramLang : "th";

function changeLang(newLang: string) {
  lang = newLang;
}

const preferScreenDesktop = screen === "desktop" ? true : false;
const isWebClient = clientType === "web" ? true : false;
const isAndroidClient = clientType === "android" ? true : false;
const isIosClient = clientType === "ios" ? true : false;
export {
  apiBaseUrl,
  lang,
  changeLang,
  hideAmount,
  hideNavbar,
  screen,
  preferScreenDesktop,
  embed,
  showConfirmButton,
  isProduction,
  debug,
  isWebClient,
  isAndroidClient,
  isIosClient,
  termConditionLink,
  clientType,
  callerDomain,
  searchPageLayout,
  isBookingAoa,
};
