import { createI18n } from "vue-i18n";
import { lang } from "./config";
import en from "@/locales/en.json";
import th from "@/locales/th.json";

const messages = {
  en,
  th,
};

const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages,
  global: true,
});

export default i18n;
