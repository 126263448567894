<template>
  <div>
    <div
      v-if="debug"
      class="p-3 text-xs bg-pink-200 h-[200px] overflow-scroll"
      id="log-wrapper"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { debug } from "@/config";
</script>
<script lang="ts">
export default {
  name: "Logger",
};
</script>
