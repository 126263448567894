import { eventManager } from "./eventManager";
import logger from "@/helper/logger";
import { ref } from "vue";
import { convertToJsonObject } from "@/helper/jsonConvert";
import useReport from "@/composable/useReport";

let webBridge = undefined as unknown as MessageEventSource;
const iosBridge = ref<AppBridge | null>(null);
const androidBridge = ref<AppBridge | null>(null);

type CallHandler = (
  eventName: string,
  payload: string | object,
  fn?: (response: any) => void
) => void;

type AppBridge = {
  registerHandler: (
    eventName: string,
    fn: (data: any, responseCallback: any) => void
  ) => void;
  callHandler: CallHandler;
};

/**
 * Web Bridge
 */
function setupWebBridge(windowRef: MessageEventSource) {
  webBridge = windowRef;
}

function getWebBridgeInstancce() {
  return webBridge;
}

function sendToWeb({
  eventName,
  payload,
  callback,
}: {
  eventName: string;
  payload: object | string;
  callback?: (responseCallback: any) => void;
}) {
  if (!webBridge) {
    logger("web bridge not defined", webBridge);
    return;
  }
  logger("send to web", { eventName, payload });
  // @ts-ignore
  webBridge.postMessage({ eventName, payload: payload }, "*");
}

function startListenFromWeb() {
  window.addEventListener("message", (event) => {
    if (event.data) {
      if (typeof event.data !== "string") {
        return;
      }

      const { eventName, payload, provider } = convertToJsonObject(event.data);

      if (provider === "hungryhub") {
        if (event.source) {
          setupWebBridge(event.source);
        }
        eventManager.emit(eventName, payload);
      }
    }
  });
}

function listenFromWeb(
  eventName: string | string[],
  handler: (payload: any) => void
) {
  if (Array.isArray(eventName)) {
    eventName.forEach((evtName) => {
      eventManager.on(evtName, handler);
      return;
    });
  } else {
    eventManager.on(eventName, handler);
  }
}

/**
 * Adnroid Bridge
 */

function setupAndroidBridge(callback: any) {
  if (window.WebViewJavascriptBridge) {
    return callback(window.WebViewJavascriptBridge);
    // setAndroidBridgeInstance(window.WebViewJavascriptBridge);
  } else {
    document.addEventListener(
      "WebViewJavascriptBridgeReady",
      function () {
        return callback(window.WebViewJavascriptBridge);

        // setAndroidBridgeInstance(window.WebViewJavascriptBridge);
      },
      false
    );
  }
}

function setAndroidBridgeInstance(bridge: any) {
  androidBridge.value = bridge;
}

function listenFromAndroid(
  eventName: string | string[],
  callback: (data: any, responseCallback: any) => void
) {
  if (!androidBridge.value) {
    logger(
      "cannot register handler for android",
      "androidBridge instance is null"
    );

    return;
  }
  if (Array.isArray(eventName)) {
    eventName.forEach((evtName) => {
      if (androidBridge.value) {
        androidBridge.value.registerHandler(evtName, callback);
      }
    });
    return;
  }
  androidBridge.value.registerHandler(eventName, callback);
}

function sendToAndroid({
  eventName,
  payload,
  callback,
}: {
  eventName: string;
  payload: string | object;
  callback?: (responseCallback: any) => void;
}) {
  if (androidBridge.value) {
    androidBridge.value.callHandler(eventName, payload, callback);
    logger("send to android", eventName);
    return;
  }
  logger("failed send to android", "android bridge is not defined");
}

/**
 * IOS Bridge
 */

function setIosBridgeInstance(bridge: any) {
  iosBridge.value = bridge;
}

function setupIosBridge(callback: any) {
  if (window.WKWebViewJavascriptBridge) {
    // @ts-ignore
    return callback(WKWebViewJavascriptBridge);
  }
  if (window.WKWVJBCallbacks) {
    return window.WKWVJBCallbacks.push(callback);
  }
  window.WKWVJBCallbacks = [callback];
  if (window.webkit) {
    try {
      if (window.webkit.messageHandlers.iOS_Native_InjectJavascript) {
        return window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(
          null
        );
      }
    } catch (error) {
      try {
        window.postMessage(null);
      } catch (error) {
        const message =
          "Sorry something went wrong, please use Safari or Google Chrome to book/order";
        useReport({
          level: "error",
          errorException: error,
          message: message,
        });
        window.alert(message);
      }
    }
  }
}

function sendToIos({
  eventName,
  payload,
  callback,
}: {
  eventName: string;
  payload: string | object;
  callback?: (responseCallback: any) => void;
}) {
  if (!iosBridge.value) {
    logger("failed send to ios", "ios bridge is not defined");
    return;
  }
  iosBridge.value.callHandler(eventName, payload, callback);
}

function listenFromIOS(
  eventName: string | string[],
  callback: (data: any) => void
) {
  if (!iosBridge.value) {
    logger("cannot register handler for ios", "iosBridge instance is null");

    return;
  }
  if (Array.isArray(eventName)) {
    eventName.forEach((evtName) => {
      if (iosBridge.value) {
        iosBridge.value.registerHandler(evtName, callback);
      }
    });
    return;
  }
  iosBridge.value.registerHandler(eventName, callback);
}

/**
 * multi platform bridge
 */

function sendToPlatform({
  eventName,
  payload,
  platform = "all",
}: {
  eventName: string;
  payload: string | object;
  platform?: "all" | "web" | "ios" | "android";
}) {
  const messagePayload = {
    eventName,
    payload,
  };
  logger("send to platform", { eventName, payload, platform });
  if (platform === "all") {
    sendToAndroid(messagePayload);
    sendToWeb(messagePayload);
    sendToIos(messagePayload);
    return;
  }
  if (platform === "android") {
    sendToAndroid(messagePayload);
    return;
  }
  if (platform === "ios") {
    sendToIos(messagePayload);
    return;
  }
  if (platform === "web") {
    sendToWeb(messagePayload);
  }
}

function listenFromPlatform({
  eventName,
  callback,
  platform = "all",
}: {
  eventName: string | string[];
  callback: (data: any) => void;
  platform?: "all" | "web" | "ios" | "android";
}) {
  if (platform === "all") {
    listenFromWeb(eventName, callback);
    listenFromAndroid(eventName, callback);
    listenFromIOS(eventName, callback);
    return;
  }
  if (platform === "android") {
    listenFromAndroid(eventName, callback);
    return;
  }
  if (platform === "ios") {
    listenFromIOS(eventName, callback);
    return;
  }
  if (platform === "web") {
    listenFromWeb(eventName, callback);
    return;
  }
}

export {
  // web
  webBridge,
  sendToWeb,
  setupWebBridge,
  getWebBridgeInstancce,
  startListenFromWeb,
  listenFromWeb,
  // android
  androidBridge,
  setupAndroidBridge,
  setAndroidBridgeInstance,
  sendToAndroid,
  listenFromAndroid,
  // ios
  setupIosBridge,
  iosBridge,
  setIosBridgeInstance,
  sendToIos,
  listenFromIOS,
  // multip platform
  sendToPlatform,
  listenFromPlatform,
};
