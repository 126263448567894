<template>
  <div>
    <router-view></router-view>
    <Logger />
  </div>
</template>

<script lang="ts" setup>
import { iosBridge, androidBridge } from "./logic/platformBridge";
import { watch } from "vue";
import { storeToRefs } from "pinia";
import {
  EVENT_ON_APP_BRIDGE_READY,
  EVENT_ON_MOUNTED,
} from "./logic/eventManager";
import { eventManager } from "./logic/eventManager";
import { sendToPlatform, startListenFromWeb } from "./logic/platformBridge";
import Logger from "@/components/Logger.vue";
import useQueryString from "@/composable/useQueryString";
import { configStore } from "@/store/config";
const useConfigStore = configStore();
const { getQueryString } = useQueryString();
const { apiDomain } = getQueryString();
const { baseUrl } = storeToRefs(useConfigStore);
function setBaseUrl() {
  if (typeof apiDomain === "string" && apiDomain.length > 0) {
    baseUrl.value = `${atob(apiDomain)}/api/v5`;
  }
}
setBaseUrl();
startListenFromWeb();

function sendMountedEvent() {
  sendToPlatform({
    eventName: EVENT_ON_MOUNTED,
    payload: "",
  });
}

watch([iosBridge, androidBridge], ([iosBridgeNewVal, androidBridgeNewVal]) => {
  if (iosBridgeNewVal || androidBridgeNewVal) {
    eventManager.emit(EVENT_ON_APP_BRIDGE_READY);
    sendMountedEvent();
  }
});
</script>
