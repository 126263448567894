import Rollbar from "@/lib/rollbar";
const env = import.meta.env;

type ReportParameter = {
  message: string;
  level: "critical" | "error" | "warning" | "debug" | "info";
  errorException?: any;
  object?: {};
};

const useReport = (paramater: ReportParameter) => {
  if (!env.PROD) {
    console.log(paramater.message, paramater.object, paramater.errorException);
    return;
  }
  const logLevel = paramater.level || "debug";
  Rollbar.configure({ logLevel });
  Rollbar.log(paramater.message, paramater.object, paramater.errorException);
};

export default useReport;
